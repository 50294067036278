import { Flex } from "@storyofams/react-ui";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Button, Text, Container, Heading, Seo } from "../components";

const messages = defineMessages({
  unauthorized: "Het lijkt erop dat je hier niet hoort te zijn.",
  defaultEror: "Zelfs wij weten niet wat er is gebeurd.",
  notFound: "We konden de pagina die je zocht niet vinden.",
  serverError:
    "Onze server had wat problemen met het verwerken van dat verzoek.",
  takeMeHome: "Breng me naar home",
});

const getError = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

const getContent = ({ statusCode, intl }) => {
  switch (statusCode) {
    case 401:
      return intl.formatMessage(messages.unauthorized);
    case 404:
      return intl.formatMessage(messages.notFound);
    case 500:
      return intl.formatMessage(messages.serverError);
    default:
      return intl.formatMessage(messages.defaultEror);
  }
};

interface ErrorPageProps {
  statusCode?: number;
}

const Error = ({ statusCode }: ErrorPageProps) => {
  const intl = useIntl();
  const content = getContent({ statusCode, intl });

  return (
    <>
      <Seo title={`${statusCode}`} description={content} />

      <Container textAlign="center">
        <Flex flexDirection="column" variant="center">
          <Heading variant="h1" as="h1" mb={[1.5, 2]}>
            {statusCode}
          </Heading>
          <Text maxWidth="400px" mx="auto" mb={5}>
            {content}
          </Text>
          <Button variant="primary" href="/">
            <FormattedMessage {...messages.takeMeHome} />
          </Button>
        </Flex>
      </Container>
    </>
  );
};

Error.getInitialProps = async ({ res, err }) => {
  return {
    props: {},
    ...getError({ res, err }),
  };
};

export default Error;
