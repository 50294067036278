import { withStory } from '@storyofams/storyblok-toolkit';
import { GetStaticProps } from 'next';
import { enhancedStaticProps, withLayout } from '~lib';
import Error from './_error';

const NotFoundPage = () => <Error statusCode={404} />;

export default withLayout(withStory(NotFoundPage));

export const getStaticProps: GetStaticProps = enhancedStaticProps(async () => {
  return {
    props: {},
  };
});
